import {
    MdVerified
} from 'react-icons/md'
import {
    FiShield
} from 'react-icons/fi'
import {
    HiOutlineSparkles
} from 'react-icons/hi'
import { useState, useEffect } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

const themeColors = {
    "orange": "#FFA500",
    "red": "#E34234",
    "green": "#4CBB17",
    "blue": "#40B5AD",
    "purple": "#953553",
    "pink": "#FF69B4",
    "yellow": "#FFDB58",
    "default": "#FFA500"
}

export default function ClanCard({ clan, fallbackIcon, key }) {
    const router = useRouter()

    const [icon, setIcon] = useState(clan?.icon || "/default_icon.png")
    const [banner, setBanner] = useState(clan?.banner || "/default_banner.png")

    return (
        <div key={key} className="group relative border-b border-r border-white/25 h-[175px] cursor-pointer" onClick={() => router.push(`/clan/${clan.tag}`)} style={{ zIndex: 5 }}>
            <div className="relative w-full h-full">
                <Image
                    src={banner}
                    alt={clan.name}
                    className="w-full h-full object-cover rounded-tl rounded-tr transition opacity-25 group-hover:opacity-50"
                    layout="fill"
                    onError={() => setBanner("/default_banner.png")}
                />
            </div>
            <div className="absolute inset-0 flex items-center justify-center" style={{ backdropFilter: "blur(10px)" }}>
                <div className="flex flex-col items-center justify-center">
                    <Image
                        src={icon}
                        alt={clan.name}
                        className="w-24 h-24 rounded-full bg-white/10 transition"
                        width={96}
                        height={96}
                        style={{
                            border: `2px solid ${themeColors[clan.theme] || themeColors["default"]}`
                        }}
                        placeholder="blur"
                        blurDataURL={fallbackIcon}
                        onError={() => setIcon("/default_icon.png")}
                    />
                    <div className="flex items-center">
                        <h3 className="text-lg font-bold text-white">
                            [{clan.tag}] {clan.name}
                        </h3>
                        <div className='mt-1 ml-1 flex'>
                            {clan.verified && (
                                <div className="tooltip" data-tip="Verified Clan">
                                    <MdVerified className={`text-xl`} style={{ color: themeColors[clan?.theme || "default"] }} />
                                </div>
                            )}
                            {clan.staffOwned && (
                                <div className="tooltip" data-tip="Staff Owned Clan">
                                    <FiShield className={`text-xl`} style={{ color: themeColors[clan?.theme || "default"] }} />
                                </div>
                            )}
                            {clan.earlyAccess && (
                                <div className="tooltip" data-tip="Early Access Clan">
                                    <HiOutlineSparkles className={`text-xl`} style={{ color: themeColors[clan?.theme || "default"] }} />
                                </div>
                            )}
                        </div>
                    </div>
                    <p className="text-sm text-white/60">
                        {clan.votes} votes
                    </p>
                </div>
            </div>
        </div>
    )
}