import Head from 'next/head'
import Image from 'next/image'
import { Fragment, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Dialog, Disclosure, Menu, Popover, Tab, Transition } from '@headlessui/react'
import { ChevronDownIcon, FunnelIcon, CheckIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import NewClanCard from '@/components/newClanCard'

const filterOptions = [
  { name: 'Verified', code: 'verified' },
  { name: 'Staff Owned', code: 'staffOwned' },
  { name: 'Early Access', code: 'earlyAccess' },
  { name: 'Competitive', code: 'competitive' }
]

const sortOptions = [
  { name: 'Featured', code: 'featured' },
  { name: 'Most Votes', code: 'most-votes' },
  { name: 'Least Votes', code: 'least-votes' },
  { name: 'Newest', code: 'newest' },
  { name: 'Oldest', code: 'oldest' }
]

const classNames = (...classes) => classes.filter(Boolean).join(' ')

export default function Home({ deleted, query: searchQuery  }) {
  const router = useRouter()

  const [query, setQuery] = useState(searchQuery || "")
  const [filters, setFilters] = useState([])
  const [sort, setSort] = useState("featured")
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [fallbackIcon, setFallbackIcon] = useState('')
  const [searching, setSearching] = useState(false)
  
  const [clansToDisplay, setClansToDisplay] = useState([])

  const searchClans = (queryToSearch, filtersToSearch, sortToSearch, newPage, scroll) => {
    if (searching) return

    setClansToDisplay([])
    setSearching(true)

    setQuery(queryToSearch)
    setFilters(filtersToSearch)
    setSort(sortToSearch)

    const url = new URL(`${window.location.origin}/api/v1/clans`)
    if (newPage) url.searchParams.append("page", newPage)
    else url.searchParams.append("page", page)
    if (queryToSearch) url.searchParams.append("query", queryToSearch)
    if (filtersToSearch) url.searchParams.append("filters", filtersToSearch)
    if (sortToSearch) url.searchParams.append("sort", sortToSearch)

    fetch(url)
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setClansToDisplay(data.clans)
          setTotalPages(data.pages)
          setFallbackIcon(data.fallback)
          setSearching(false)

          if (scroll) window.scrollTo(0, 0)
        }
      })
      .catch(err => {
        console.log(err)
        setSearching(false)
      })
  }

  useEffect(() => {
    searchClans(query, filters, sort)
  }, [router.query])

  const [showDeleteNotice, setShowDeleteNotice] = useState(deleted)

  const hideNotice = () => {
    setShowDeleteNotice(false)
    window.history.replaceState({}, document.title, "/" + window.location.pathname.split("/")[1]);
  }

  return (
    <>
      <Head>
        <title>Explore Clans | Combat Warriors</title>
        <meta name="description" content="Search and explore the best Combat Warriors clans!" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      
      <main>
        <div className="min-h-full place-items-center bg-black py-24 select-none">
          <div className="text-center">

            {showDeleteNotice && (
              <div className="rounded-md bg-green-50 p-4 w-1/2 m-auto text-left mb-3">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">Clan deleted successfully!</h3>
                    <div className="mt-2 text-sm text-green-700">
                      <p>You successfully deleted your clan!</p>
                    </div>
                    <p className="mt-3 text-sm">
                      <p className="flex cursor-pointer font-medium text-green-700 hover:text-green-500 transition" onClick={hideNotice}>
                        <CheckIcon className="mr-1.5 h-5 w-5 text-green-500 group-hover:text-green-500" aria-hidden="true" /> Dismiss
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            )}

            <h1 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-5xl">Explore Clans</h1>
            <p className="mt-2 max-w-md mx-auto text-base text-white/50 md:max-w-3xl">
              Search and explore the best Combat Warriors clans!
            </p>

            <main className="pb-24 mt-12">
              {/* Filters */}
              <Disclosure
                as="section"
                aria-labelledby="filter-heading"
                className="grid items-center border-b border-t border-white/25"
              >
                <h2 id="filter-heading text-white" className="sr-only">
                  Filters
                </h2>
                <div className="relative col-start-1 row-start-1 py-4">
                  <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-white/25 px-4 text-sm sm:px-6 lg:px-8">
                    <Menu as="div" className="relative inline-block">
                        <div className="flex">
                          <Menu.Button className="inline-flex justify-center text-sm font-medium text-white">
                            <FunnelIcon
                              className="mr-2 h-5 w-5 flex-none text-white"
                              aria-hidden="true"
                            />
                            {filters.length || 0} Filter{filters.length > 1 || filters.length == 0 ? "s" : ""}
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {filterOptions.map((option) => (
                                <Menu.Item key={option.name}>
                                  {({ }) => (
                                    <button
                                      className={classNames(
                                        filters.includes(option.code) ? 'font-medium text-gray-900' : 'text-gray-500',
                                        'block px-4 py-2 text-sm w-full hover:bg-gray-200 transition text-left'
                                      )}
                                      onClick={() => {
                                        if (filters.includes(option.code)) {
                                          setFilters(filters.filter(f => f !== option.code))
                                          searchClans(query, filters.filter(f => f !== option.code), sort, 1)
                                          setPage(1)
                                        } else {
                                          setFilters([...filters, option.code])
                                          searchClans(query, [...filters, option.code], sort, 1)
                                          setPage(1)
                                        }
                                      }}
                                    >
                                      {option.name} {filters.includes(option.code) && <CheckIcon className="-mt-1 h-4 w-4 inline" aria-hidden="true" />}
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                </div>
                <div className="col-start-1 row-start-1 py-4">
                  <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
                    <Menu as="div" className="relative inline-block">
                      <div className="flex">
                        <Menu.Button className="inline-flex justify-center text-sm font-medium text-white">
                          Sort
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {sortOptions.map((option) => (
                              <Menu.Item key={option.name}>
                                {({ }) => (
                                  <button
                                    className={classNames(
                                      option.code === sort ? 'font-medium text-gray-900' : 'text-gray-500',
                                      'block px-4 py-2 text-sm w-full hover:bg-gray-200 transition text-left'
                                    )}
                                    onClick={() => {
                                      if (option.code === sort) return
                                      setSort(option.code)
                                      searchClans(query, filters, option.code, 1)
                                      setPage(1)
                                    }}
                                  >
                                    {option.name} {option.code === sort && <CheckIcon className="-mt-1 h-4 w-4 inline" aria-hidden="true" />}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </Disclosure>

              {/* Product grid */}
              <section aria-labelledby="products-heading" className="mx-auto max-w-9xl overflow-hidden sm:px-6 lg:px-8">
                <h2 id="products-heading" className="sr-only">
                  Clans
                </h2>

                <div className="-mx-px space-y-1 md:space-y-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 border-l border-white/25">
                  {searching && (
                    <div className="flex flex-col items-center justify-center w-full h-96 col-span-3">
                      <svg className="animate-spin h-12 w-12 text-white" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>
                      <p className="text-white text-2xl mt-5">Searching...</p>
                    </div>
                  ) || clansToDisplay.length === 0 && (
                    <div className="flex flex-col items-center justify-center w-full h-96 col-span-3">
                      <p className="text-white/75 text-8xl">:(</p>
                      <p className="text-white text-2xl mt-5">No clans found!</p>
                    </div>
                  ) || clansToDisplay.map((clan, i) => (
                    <NewClanCard clan={clan} fallbackIcon={fallbackIcon} key={i} />
                  ))}
                </div>
              </section>

              {/* Pagination */}
              <nav
                aria-label="Pagination"
                className="mx-auto mt-6 flex max-w-7xl justify-between px-4 text-sm font-medium text-gray-700 sm:px-6 lg:px-8"
              >
                <div className="flex min-w-0 flex-1 justify-start">
                  <button
                    className={`inline-flex h-10 items-center rounded-md border border-white/25 text-white bg-white/25 w-[100px] justify-center hover:bg-white/50 transition ${page === 1 ? "bg-white/50" : ""} disabled:opacity-50 disabled:cursor-not-allowed`}
                    onClick={() => {
                      if (page === 1) return
                      setPage(page - 1)
                      searchClans(query, filters, sort, page - 1, true)
                    }}
                    disabled={page === 1 || searching}
                  >
                    Previous
                  </button>
                </div>

                <div className="flex min-w-0 flex-1 justify-end">
                  <button
                    className={`inline-flex h-10 items-center rounded-md border border-white/25 text-white bg-white/25 w-[100px] justify-center hover:bg-white/40 transition ${page === totalPages ? "bg-white/40" : ""} disabled:opacity-50 disabled:cursor-not-allowed`}
                    onClick={() => {
                      if (page === totalPages) return
                      setPage(page + 1)
                      searchClans(query, filters, sort, page + 1, true)
                    }}
                    disabled={page === totalPages || searching}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </main>

          </div>
        </div>
      </main>
    </>
  )
}

export async function getServerSideProps(ctx) {
  return {
    props: {
      location: process.env.SITE_URL,
      deleted: ctx.query?.deleted && ctx.query?.deletedClan ? true : false,
      query: ctx.query?.query ? ctx.query.query : null,
    }
  }
}